import React from "react";
import { Link } from "gatsby";
import Modal from "../../components/ModalVideo/Modal";

import imgSBR from "../../assets/image/home-2/png/round-shape-blue.png";
import imgBP from "../../assets/image/home-2/png/dot-pattern-blue.png";
import imgH from "../../assets/image/home-2/png/hero-2-img-1.png";
import imgH2 from "../../assets/image/home-2/png/hero-2-img-2.png";

const Hero = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row justify-content-center">
            {/* <!-- Hero Content --> */}
            <div
              className="col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1"
              data-aos="fade-right"
              data-aos-delay="500"
            >
              <div className="pr-lg-10 pr-xl-0 pt-0 pt-lg-0 pb-13 pb-lg-0">
                <h1 className="font-size-10 mb-8">
                  From app idea to profitable business.
                </h1>
                <p className="font-size-7 mb-0 heading-default-color">
                  We are full-service software development company. Business
                  through strategy, design and app development.
                </p>
                <div className=" pt-5 pt-lg-10">
                  <Link
                    href="mailto:support@appshand.com"
                    className="btn btn-dodger-blue-2 rounded-5"
                  >
                    Let's Talk
                  </Link>
                  {/* <span className="d-block pt-5 pt-lg-9 heading-default-color">
                    Still confused?
                    <Modal className="ml-2">Check our 1 min video</Modal>
                  </span>*/}
                </div>
              </div>
            </div>
            {/* <!-- End Hero Content --> */}
            {/* <!-- l2-hero-image-group --> */}
            <div
              className="col-xl-7 col-lg-6 col-md-7 order-1 order-lg-2"
              data-aos="fade-left"
              data-aos-delay="800"
            >
              <div className="l2-hero-image-group mt-5 mt-lg-0">
                <div className="img-1">
                  <img src={imgSBR} alt="" />
                </div>
                <div className="img-2">
                  <img src={imgBP} alt="" />
                </div>
                <div className="img-3">
                  <img src={imgH} alt="" />
                </div>
                <div className="img-4">
                  <img src={imgH2} alt="" />
                </div>
                {/* <!-- Image Group Content --> */}
                {/* <!-- End Image Group Content --> */}
              </div>
            </div>
            {/* <!-- End l2-hero-image-group --> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
